import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, catchError, finalize, of } from 'rxjs';
import { VhImage } from '../models/api-models/image';
import { SnackBarService } from './snack-bar.service';
import { HttpClient } from '@angular/common/http';
import { hosts } from '../constants/hosts';

@Injectable({
  providedIn: 'root'
})
export class SupplierImagesService {
  private _dataLoading$ = new BehaviorSubject<boolean>(false);
  public get dataLoading$(): Observable<boolean> { return this._dataLoading$.asObservable(); }

  private _supplierLogo$ = new BehaviorSubject<VhImage | null>(null);
  public get supplierLogo$(): Observable<VhImage | null> { return this._supplierLogo$.asObservable(); }

  private _supplierIcon$ = new BehaviorSubject<VhImage | null>(null);
  public get supplierIcon$(): Observable<VhImage | null> { return this._supplierIcon$.asObservable(); }

  private _currentSupplierId = 0;

  constructor(
    private http: HttpClient,
    private snackBar: SnackBarService
  ) {
  }

  public getSupplierImages(supplierId: number) {
    if (this._currentSupplierId === supplierId && (this._supplierIcon$.value || this._supplierLogo$.value)) {
      return;
    }

    this._currentSupplierId = supplierId;
    this._supplierLogo$.next(null);
    this._supplierIcon$.next(null);

    this._dataLoading$.next(true);

    this.http.get(`${hosts.distributionProperty}/supplier/${this._currentSupplierId}/images`)
      .pipe(
        catchError(err => {
          this.snackBar.showError(`Error getting supplier images: ${err}`);
          return of(null as any);
        }),
        finalize(() => this._dataLoading$.next(false)))
      .subscribe((images: any) => {
        if (images?.length) {
          images.forEach((item: any) => {
            if (item.category === 'logo') {
              this._supplierLogo$.next(new VhImage({ url: item.url }));
            } else {
              this._supplierIcon$.next(new VhImage({ url: item.url }));
            }
          });
        }
      });
  }
}
